@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "~@flaticon/flaticon-uicons/css/all/all.css";
@import "react-image-gallery/styles/css/image-gallery.css";

.slick-next {
  right: -15px;
}
.slick-prev {
  left: -15px;
}
.slick-arrow {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 6px 21px #efefef;
  cursor: pointer;
  height: 40px;
  width: 40px;
  z-index: 5;
  transition: all 0.3s ease 0s;

  &::before {
    content: "\f170";
    font-family: uicons-regular-rounded;
    font-style: normal;
    color: #333;
    opacity: 1;
    font-size: 12px;
  }
  &.slick-prev {
    &::before {
      content: "\f16e";
    }
  }
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: var(--color-primary);
  color: #fff;
  &::before {
    color: #fff;
  }
}

.slick-arrow {
  height: 82px;
  width: 42px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  box-shadow: none;
  background: transparent;
  @media (max-width: 576px) {
    height: 42px;
  }

  &::before {
    color: #fff;
  }
  &:hover {
    background: #fff;
    &::before {
      color: #333;
    }
  }

  &.slick-next {
    right: 25px;
    @media (max-width: 576px) {
      right: 10px;
    }
  }
  &.slick-prev {
    left: 25px;
    @media (max-width: 576px) {
      left: 10px;
    }
  }
}
